import { IShrlRoutes } from '../../shrl.routes';
import { EUserRole } from './user.model';

export enum ENavigationUnitComponentSelection {
  TOPBAR,
  SIDENAV,
  FOOTER,
}

export interface INavigationData {
  componentSelection?: ENavigationUnitComponentSelection[];
  visibleForUsers?: EUserRole[];
  label?: string;
  iconName?: string;
  breadcrumbCode?: string;
  breadcrumbLabel?: string;
}

export interface INavigationDataTreeUnit extends INavigationData {
  navigationChildren?: INavigationDataTreeUnit[];
  fullPath: string;
  matchFullPath?: boolean;
  matchPartialPath?: boolean;
  isRedirected?: boolean;
}

export enum ENavigationRoute {
  ERROR = 'Error',
  ABOUT_US = '',
  HOW_IT_WORKS = 'how-it-works',
  FAQ = 'faqs',
  CONTACT = 'contact',
  IMPRINT = 'imprint',
  REGISTER = 'register',
  LOGIN = 'login',
  PASSWORD_REMIND = 'password-remind',
  PASSWORD_CHANGE = 'password-change',
  REGULATIONS = 'regulations',
  TERMS = 'terms-and-conditions',
  PRIVACY_POLICY = 'privacy-policy',
  SUPPORT = 'support',
  PROPERTIES = 'properties',
  NEW = 'new',
  EDIT = 'edit',
  GUEST = 'guest',
  ACCOUNT = 'account',
  CONTACT_DETAILS = 'contact-details',
  COMPANY_DETAILS = 'company-details',
  ACCOUNT_SETTINGS = 'account-settings',
  ACCOUNT_ACTIVATE = 'account-activate',
  NOTIFICATIONS = 'notifications',
  FIBARO = 'fibaro',
  ROOM = 'room',
  DETAILS = 'details',
  RESERVATION = 'reservation',
  LIST = 'list',
  SUBSCRIPTIONS = 'subscriptions',
  DASHBOARD = 'dashboard',
  USERS = 'users',
  USER = 'user',
  USER_INFO = 'user-info',
  APARTMENTS = 'apartments',
  ROOMS = 'rooms',
  RESERVATIONS = 'reservations',
  DISCOUNTS = 'discounts',
  REQUESTS = 'requests',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  CONFIGURED = 'configured',
}

function arrayContainsArray(mainArray?: unknown[], subArray?: unknown[]) {
  return subArray?.every((item) => mainArray?.includes(item));
}

function getFullPath(parentPath: string, path?: string) {
  return !parentPath && !path ? '/' : parentPath + (path ? '/' + path : '');
}

// filter isn't currently use, I will leave that implementation for future usage
// if there isn't any usage it should be removed
function buildNavigationTree(
  routes: IShrlRoutes,
  componentVisibilityFilter?: ENavigationUnitComponentSelection[],
  parentPath: string = ''
): INavigationDataTreeUnit[] {
  return routes
    .filter((route) => route.path != null)
    .filter(
      (route) =>
        !componentVisibilityFilter ||
        arrayContainsArray(
          route.data?.componentSelection,
          componentVisibilityFilter
        )
    )
    .map((route) => {
      const fullPath = getFullPath(parentPath, route.path);

      const navUnit: INavigationDataTreeUnit = {
        fullPath: fullPath,
        visibleForUsers: route.data?.visibleForUsers,
        label: route.data?.label,
        iconName: route.data?.iconName,
        navigationChildren: [],
        componentSelection: route.data?.componentSelection,
      };

      if (route.children) {
        navUnit.navigationChildren = buildNavigationTree(
          route.children,
          componentVisibilityFilter,
          fullPath
        );
      }

      return navUnit;
    });
}

export function getNavigationTree(
  routes: IShrlRoutes,
  componentVisibilityFilter?: ENavigationUnitComponentSelection[]
): INavigationDataTreeUnit[] {
  return buildNavigationTree(routes, componentVisibilityFilter);
}
